export const languages = {
  English: {
    privacy: {
      privacyPolicy: "Privacy policy",
      content: `"We collect and process some of your personal information to allow us to operate as a business, provide you with our services and to meet the legal obligations we have as a regulated financial services firm.
As a technology company we also use a number of third parties to carry out certain business functions which may require us to share your information with them.
This privacy notice details the personal information we collect and use, how we look after it, and the circumstances in which we may share it with someone else. It also sets out your rights around how we handle your information and lets you know how to contact us if you have any concerns."`,
    },
    landing: {
      privacyPolicy: "Privacy policy",
      advancedToolsTitle: "Advanced Trading Tools:",
      advancedToolsContent:
        "Unlock a world of possibilities with our advanced trading tools designed to cater to traders of all levels. Dive into customizable charting features equipped with an array of technical indicators, allowing you to visualize market movements with precision. Manage risks effectively with our comprehensive risk management tools, and execute trades seamlessly with various order types, including market orders, limit orders, and stop orders. Take your trading to the next level with our algorithmic trading capabilities, empowering you to automate your strategies and maximize your potential returns.",
      analyticsTitle: "Analytics and Market Insights:",
      analyticsContent:
        "Gain a competitive edge in the markets with our powerful analytics and market insights. Harness the power of advanced data analytics to identify trends, patterns, and opportunities across various asset classes. Stay ahead of the curve with sentiment analysis, providing you with invaluable insights into market sentiment and investor behavior. Dive deep into fundamental analysis, enabling you to make informed decisions based on the underlying factors driving asset prices. Stay informed with real-time market news and updates, ensuring you never miss a beat in the fast-paced world of trading.",
      educationalResourcesTitle:
        "Educational Resources and Community Engagement:",
      educationalResourcesContent:
        "Empower yourself with knowledge through our extensive library of educational resources and community engagement features. Learn at your own pace with our comprehensive tutorials, articles, and market analysis, covering a wide range of topics from basic trading concepts to advanced strategies. Engage with a vibrant community of traders, share insights, and learn from each other's experiences through features such as social trading, discussion forums, and real-time chat rooms. Whether you're a novice trader or a seasoned pro, our educational resources and community engagement features are here to support you on your trading journey.",
      createPortfolioTitle: "Create your portfolio today",
      powerfulTradingPlatformTitle: "Powerful Trading Platform:",
      powerfulTradingPlatformContent:
        "Gain access to a powerful trading platform equipped with advanced tools and features designed to elevate your trading experience. From customizable charting to algorithmic trading capabilities, our platform provides everything you need to succeed in the markets.",
      unlimitedWithdrawalsTitle: "Unlimited Withdrawals:",
      unlimitedWithdrawalsContent:
        "Experience the freedom to access your funds whenever you need them with our unlimited withdrawal policy. No limits, no restrictions – just seamless access to your funds.",
      supportTitle: "24/7 Support",
      supportContent:
        "Enjoy peace of mind knowing that help is always available with our 24/7 customer support. Whether you have questions, concerns, or need technical assistance, our dedicated support team is here to help, day or night.",
      joinCommunityTitle: "Join a Thriving Community",
      joinCommunityContent:
        "Become part of a vibrant trading community where you can connect with fellow traders, share insights, and learn from each other's experiences. Our community forums and social trading features provide valuable opportunities for collaboration and growth.",
      Moto: "Trade easy, Trade smart!",
      WhatToGet: "What will you get",
      Home: "Home",
      About: "About",
      Markets: "Markets",
      Pricing: "Pricing",
      Register: "Register",
      LogIn: "Log in",
      Discover: "Discover a new ways to enjoy your World!",
      Experience: "Experience Drives Success",
      JoinNow: "Join now",
      Charting:
        "Charting Your Financial Future: Where Expertise Meets Opportunity",
      ReadMore: "READ MORE",
      CoreServices: "Our Core Services",
      ProactiveManagement:
        "Proactive management ensuring balanced portfolios aligned with your investment objectives.",
      PersonalizedConsultancy: "Personalized Investment Consultancy",
      ComprehensiveAnalysis: "Comprehensive Market Analysis",
      PortfolioManagement: "Portfolio Management Solutions",
      MultiMarketTrading: "Multi-Market Trading Platform",
      Forex: "Forex",
      Stocks: "Stocks",
      Commodities: "Commodities",
      Futures: "Futures",
      Crypto: "Crypto",
      CFDs: "CFDs",
      PricingPlans: "Our Pricing Plans",
      StarterPlan: "Starter Plan – 250 EUR",
      TraderPlan: "Trader Plan – 500 EUR to 1000 EUR",
      AdvancedPlan: "Advanced Plan – 500 – EUR",
      SignIn: "Sign in",
      Email: "Email",
      Password: "Password",
      DontHaveAccount: "Don't have account?",
      AccountTypesTitle: "Account types",
      Standard: "Standard",
      Advanced: "Advanced",
      TradeWith: "Trade with",
      TrustYearsTitle: "20+ Years of Expertise: Trust the Market Experts",
      FastAndSecure: "Fast and secure way to purchase or exchange 150+ symbols",
    },
    markets: {
      forex: {
        name: "Forex",
        heading: "The Global Currency Market",
        navigate: "/markets/forex",
        mainInfo:
          "Currency trading takes place in the foreign exchange market. Currency is vital because it allows people to buy goods and services both locally and across borders.",
        description: [
          "The foreign exchange market, also known as Forex or FX, is a global decentralized or over-the-counter (OTC) market for the trading of currencies.",
          "This market determines foreign exchange rates for every currency. It includes all aspects of buying, selling, and exchanging currencies at current or determined prices.",
          "Forex trading is conducted electronically over-the-counter (OTC), meaning that transactions occur via computer networks between traders around the world, rather than on a centralized exchange.",
        ],
      },
      crypto: {
        name: "Crypto",
        navigate: "/markets/crypto",
        heading: "Cryptocurrency Revolution",
        mainInfo:
          "Cryptocurrency is a digital or virtual currency that uses cryptography for security and operates independently of a central bank.",
        description: [
          "Crypto markets are decentralized and based on blockchain technology, allowing for secure and transparent transactions.",
          "Bitcoin, created by an unknown person or group of people using the pseudonym Satoshi Nakamoto, was the first cryptocurrency and remains the most well-known and widely used.",
          "Blockchain, the underlying technology behind cryptocurrencies, is a distributed ledger that records all transactions across a network of computers, providing security and transparency.",
        ],
      },
      stocks: {
        name: "Stocks",
        navigate: "/markets/stocks",
        heading: "Investing in Businesses",
        mainInfo:
          "Stock markets represent exchanges where buyers and sellers of stocks, which are ownership claims on businesses, come together to trade.",
        description: [
          "Investing in stocks offers potential for capital appreciation and dividends, but also involves risks such as market volatility.",
          "Stocks are categorized into different sectors, such as technology, healthcare, and consumer goods, allowing investors to diversify their portfolios.",
          "Stock market indices, such as the S&P 500 and Dow Jones Industrial Average, track the performance of a group of stocks and serve as benchmarks for the broader market.",
        ],
      },
      cfds: {
        name: "CFDs",
        navigate: "/markets/cfds",
        heading: "Trading Without Ownership",
        mainInfo:
          "Contracts for Difference (CFDs) are derivative financial instruments that allow traders to speculate on the price movements of various assets without owning the underlying asset.",
        description: [
          "CFD trading provides opportunities for leverage and allows traders to go long (buy) or short (sell) on asset prices.",
          "CFDs allow traders to access a wide range of markets, including stocks, commodities, currencies, and indices, without the need for physical ownership of the underlying asset.",
          "CFD trading is popular among traders seeking flexibility and the ability to profit from both rising and falling markets.",
        ],
      },
      futures: {
        name: "Futures",
        navigate: "/markets/futures",
        heading: "Predicting Future Prices",
        mainInfo:
          "Futures contracts are standardized agreements to buy or sell assets at a predetermined price and date in the future.",
        description: [
          "They are traded on organized exchanges and are used by investors to manage risk and speculate on price movements.",
          "Futures contracts exist for various assets, including commodities (such as gold, oil, and wheat), financial instruments (such as stock indices and interest rates), and currencies.",
          "Futures trading involves predicting future price movements and can be highly speculative and volatile.",
        ],
      },
      commodities: {
        name: "Commodities",
        navigate: "/markets/commodities",
        heading: "Trading Basic Goods",
        mainInfo:
          "Commodities markets involve the trading of primary goods such as energy, metals, and agricultural products.",
        description: [
          "Investing in commodities allows for diversification of investment portfolios and hedging against inflation.",
          "Commodities markets are influenced by various factors, including supply and demand dynamics, geopolitical events, and economic indicators.",
          "Commodities trading involves buying and selling futures contracts or spot contracts of commodities, providing opportunities for both hedging and speculation.",
        ],
      },
    },
    aboutUs: {
      header: "About us",
      content:
        "MS Limited is a leading global technology-enabled liquidity provider in financial products. Founded in 1997, we continuously grow our team and organization, expanding our product offering, large counterparty network and trading capabilities to ensure that our trading hubs in Europe and APAC can provide liquidity across all major exchanges and trading platforms, globally, 24 hours a day. Our culture is unique. We are entrepreneurs at heart and drive innovation across financial markets. We are a team of passionate, creative minds working together across multi functions and countries.",
      button: "Get Started",
      securityTitle: "Funds Security",
      securityContent:
        "We have been offering clients safe and secure ways to manage global payments. Rest assured, we use state-of-the-art encryption and security techniques to ensure all our internal and online systems are impenetrable.",
      regulationTitle: "MS Limited is regulated by law",
      regulationContent:
        "As a financial institution, MS Limited is required by law to conduct business in a manner consistent with the best interests of its clients and in accordance with all national regulations governing foreign exchange companies and money transmitters. We are dedicated to operating in a safe and sound manner, with the highest legal and ethical standards. To ensure that safety, we have established strict standards of compliance with the regulations in the jurisdictions in which operate. We work with the most respected institutional banks in the world We maintain bank accounts with some of the most respected institutional banks in the world, where customers deposit and settle payments. We take security very seriously and conduct business in a manner consistent with the best interests of our clients and our partners.",
      backgroundTitle: "Background",
      backgroundContent: [
        " We collect and process some of your personal information to allow us to operate as a business, provide you with our services and to meet the legal obligations we have as a regulated financial services firm.",
        "As a technology company we also use a number of third parties to carry out certain business functions which may require us to share your information with them.",
        "This privacy notice details the personal information we collect and use, how we look after it, and the circumstances in which we may share it with someone else. It also sets out your rights around how we handle your information and lets you know how to contact us if you have any concerns.",
      ],
      whenWeuseTitle: "When you use our product",
      whenWeuseContent: [
        " Personal information you provide to us when signing up as our customer. This includes full name, date of birth, postal address, email address, nationality and national ID number       ",
        "Documents we request in order to verify your identity, carry out anti-money laundering checks and security checks",
        "Details of the bank account you use to fund your account with us",
        "Transactional and aggregate account information relating to the investments you make using our product",
        "Technical information on your use of our app, including your IP address, operating system and device ID",
        "Analytics on how you use our product",
        "Tax declarations that you make to us",
      ],
      yourRightsTitle: "Your rights",
      yourRightsContent: [
        "You have rights under the law and this privacy notice in relation to your personal data. If you would like to make a request in relation to these rights, then please contact us by email. You have the right to:",
        "request to access the information we hold about you.",
        "ask us to update any of your information which is inaccurate or incorrect.",
        "ask us to delete, stop processing or limit our use of your information that we hold (although if we have a legal obligation to retain this information, we are unable to delete it until the required retention period has elapsed).",
        "ask us to send you or another organisation an electronic copy of your personal data (portability).",
      ],
      howToContactTitle: "How to contact us",
      howToContactContent: [
        "If you have any questions about this Notice, how we handle your personal data or you are looking to exercise one of your rights, then please contact us via email: support@MS-limited.co  and we will do our best to resolve the matter.",
      ],
      howToContact: "support@MS-limited.co ",
      whatWeCollectTitle: "What information we collect",
      whatWeCollectContent:
        "To provide you with our services, we collect and use the following personal data about you at various points during your interactions with us. Personal data is information which relates to an identified or identifiable living individual, either on its own or combined with other available information.",
      leverageTitle: "Leverage",
      leverageContent: [
        "When a position is open in the Forex market, the market moves either towards or against the position of the trader. Each item in the price move corresponds to a fixed amount of capital that is added or subtracted from the balance of the trading account.",
        "If the market moves in the direction of the trader’s position, he makes money; if not, the trader is losing money. Currency trading is made in the form of ‘contracts’ for a certain number of so-called standard lots.",
        "Each lot is equivalent to 100,000 units of currency. If the dollar is used as the quotation currency and the trader opens a position for one standard lot, he buys or sells 100,000 units of this currency.",
        "Since the price move of a currency is measured in points – that is, each item has a share of 0.0001, – when trading with a standard lot, each item costs $ 10 (0.0001 x $ 100,000 = $ 10).",
        "If the transaction brings 10 points of profit, the trader earns $ 100. If the price goes 10 points to the opposite side of the position, then the trader loses $ 100.",
        "Not everyone has such a capital that allows you to trade currencies in the amount of $ 100,000, so you can use leverage, that is, borrow money from a broker to make a deal for $ 100,000 in the absence of $ 100,000 in your trading account.",
        "When you use leverage when you open a position, you receive capital loan, but this money does not actually come to you to the account. However, you see how the current result of an open position changes, because now each item is more expensive, and price movement in one direction or another can potentially bring a higher profit or loss.",
        "When performing trading operations on the “Margin Trading” terms, a relatively small change in the instrument rate can have a significant impact on the status of the Client’s trading account due to the effect of leverage.",
        "When the market moves against the position of the Client, he may incur a loss in the amount of the initial deposit and any additional funds deposited by him to maintain open positions.",
        "The client is fully responsible for taking into account all risks, using financial resources and choosing an appropriate trading strategy. When executing trading operations under margin trading conditions, even small market movements may have great impact on a Client’s trading account due to the effect of leverage.",
        "The Client must consider that if the trend on the market is against them, the Client may sustain a total loss of their initial margin and any additional funds deposited to maintain open positions. The Client shall hold full responsibility for all risks, financial resources used and the chosen trading strategy.",
      ],
      refundTitle: "Refund Policy",
      refundContent: [
        "The Policy of Return of Money was developed for the purpose of reducing financial and legal risks of the company, observance of the principles of the Policy of counteraction of money-laundering and forgery of financing terrorist activity.",
        "The company has the right to unilaterally block an entrance to the private profile, suspend the trade activity on accounts, cancel the demand for input-output, or to return money if the source of money or activity of the client contradicts the Policy of money laundering and financing of terrorism.",
        "The company doesn’t cancel the realized commercial transactions, and therefore reserves the right to return money to the sender, if within a month from the moment of replenishment, activity wasn’t recorded on trading accounts.",
        "The company reserves the right for certain objective reasons, and in case the need to make return of money arises, funds will be received through all payment systems, including credit/debit cards.",
        "Thus, the return of money will be executed on electronic platforms, and the bank details which the client used at input of money is where the transaction will take place.",
        "In the event that the company classifies activity of the client as inappropriate, or contradicting the usual purpose of use of company services where there is direct, or indirect, illegal, or unfair intention, the company reserves the right to work within this document, without informing the client beforehand.",
        "Upon replenishing the trading account by cash card, the client is required not to submit the application for confirmation of the payment which is already enlisted into the trading account to the bank, or to supplier of the credit/debit cash card, both in time, and after completion of use of company services.",
        "Nevertheless, if the company receives a payment comment according to the transaction, we reserve the right to block entrance to the private profile, and freeze the current balance of the client, as well as send money back on personal account of the client, following the payment of all services and commissions.",
        "The company will take all necessary measures to prevent and block both input and withdrawal by third parties of money from the customer account.",
        "Input and output of money from the account can be carried out only by the owner of that particular account.",
        "Once a withdrawal request is submitted, it can take MS Limited up to three business days to process the request.",
        "Once the request has been approved, please allow an additional 5-7 business days for the funds to show in your account.",
        "Cancelling your account – There is no additional cost if you would like to cancel your account, just place a withdrawal request, and inform us about your desire to close your account.",
        "Withdrawals will be processed as per the withdrawal procedure outlined above.",
      ],
    },
    rest: {
      SignUp: "Sign up",
      Email: "Email",
      Password: "Password",
      LastName: "Last Name",
      FirstName: "First Name",
      Address: "Address",
      PostalCode: "Postal code",
      Phone: "Phone",
      PromoCode: "Promo code",
      Country: "Country",
      Currency: "Currency",
      Register: "Register",
      Navigate: "Navigate",
      AboutUs: "About us",
      Markets: "Markets",
      Pricing: "Pricing",
      Account: "Account",
      SignIn: "Sign in",
      GetInto: "Get into",
      Trading: "Trading",
      AllreadyHave: "Already have an account?",
      Loading: "Loading",
    },
  },
  French: {
    landing: {
      privacyPolicy: "Politique de confidentialité",
      advancedToolsTitle: "Outils de trading avancés :",
      advancedToolsContent:
        "Débloquez un monde de possibilités avec nos outils de trading avancés conçus pour répondre aux besoins des traders de tous niveaux. Plongez dans des fonctionnalités de graphiques personnalisables équipées d'une gamme d'indicateurs techniques, vous permettant de visualiser les mouvements du marché avec précision. Gérez efficacement les risques avec nos outils complets de gestion des risques, et exécutez des transactions en toute transparence avec différents types d'ordres, y compris les ordres au marché, les ordres à cours limité et les ordres stop. Passez à la vitesse supérieure avec nos capacités de trading algorithmique, vous permettant d'automatiser vos stratégies et de maximiser vos rendements potentiels.",
      analyticsTitle: "Analyses et aperçus du marché :",
      analyticsContent:
        "Gagnez un avantage concurrentiel sur les marchés avec nos puissantes analyses et aperçus du marché. Exploitez la puissance des analyses de données avancées pour identifier les tendances, les motifs et les opportunités dans diverses classes d'actifs. Restez en avance sur la courbe avec l'analyse des sentiments, qui vous offre des informations précieuses sur le sentiment du marché et le comportement des investisseurs. Plongez profondément dans l'analyse fondamentale, ce qui vous permet de prendre des décisions éclairées basées sur les facteurs sous-jacents qui influent sur les prix des actifs. Restez informé avec les actualités et les mises à jour du marché en temps réel, vous assurant de ne jamais manquer une opportunité dans le monde effréné du trading.",
      educationalResourcesTitle:
        "Ressources éducatives et engagement communautaire :",
      educationalResourcesContent:
        "Donnez-vous les moyens du savoir grâce à notre vaste bibliothèque de ressources éducatives et de fonctionnalités d'engagement communautaire. Apprenez à votre rythme avec nos tutoriels complets, articles et analyses de marché, couvrant un large éventail de sujets, des concepts de trading de base aux stratégies avancées. Engagez-vous avec une communauté dynamique de traders, partagez des idées et apprenez des expériences des autres grâce à des fonctionnalités telles que le trading social, les forums de discussion et les salles de chat en temps réel. Que vous soyez un trader novice ou un professionnel aguerri, nos ressources éducatives et nos fonctionnalités d'engagement communautaire sont là pour vous soutenir dans votre parcours de trading.",
      createPortfolioTitle: "Créez votre portefeuille aujourd'hui",
      powerfulTradingPlatformTitle: "Plateforme de trading puissante :",
      powerfulTradingPlatformContent:
        "Accédez à une plateforme de trading puissante équipée d'outils et de fonctionnalités avancés conçus pour améliorer votre expérience de trading. Des graphiques personnalisables aux capacités de trading algorithmique, notre plateforme fournit tout ce dont vous avez besoin pour réussir sur les marchés.",
      unlimitedWithdrawalsTitle: "Retraits illimités :",
      unlimitedWithdrawalsContent:
        "Profitez de la liberté d'accéder à vos fonds quand vous en avez besoin avec notre politique de retrait illimité. Aucune limite, aucune restriction - juste un accès transparent à vos fonds.",
      supportTitle: "Support 24/7 :",
      supportContent:
        "Profitez de la tranquillité d'esprit en sachant que l'aide est toujours disponible avec notre support client 24/7. Que vous ayez des questions, des préoccupations ou besoin d'assistance technique, notre équipe de support dédiée est là pour vous aider, jour et nuit.",
      joinCommunityTitle: "Rejoignez une communauté florissante :",
      joinCommunityContent:
        "Faites partie d'une communauté de trading dynamique où vous pouvez vous connecter avec d'autres traders, partager des idées et apprendre des expériences des autres. Nos forums communautaires et nos fonctionnalités de trading social offrent des opportunités précieuses de collaboration et de croissance.",
      Home: "Accueil",
      About: "À propos de nous",
      Markets: "Marchés",
      Pricing: "Forfaits",
      Register: "Inscription",
      LogIn: "Connexion",
      Discover:
        "Découvrez de nouvelles façons de profiter de votre monde d’investissement !",
      Experience: "L'expérience est le moteur du succès",
      JoinNow: "Rejoindre maintenant",
      Charting:
        "Tracer votre avenir financier : où l'expertise rencontre l'excellence",
      ReadMore: "En savoir plus",
      CoreServices: "Nos services de base",
      ProactiveManagement:
        "Gestion proactive assurant des portefeuilles équilibrés et alignés avec vos objectifs d'investissement.",
      PersonalizedConsultancy: "Consultation d’investissement personnalisé",
      ComprehensiveAnalysis: "Analyse complète du marché",
      PortfolioManagement: "Solutions de gestion de portefeuille",
      MultiMarketTrading: "Plateforme de trading multi-marchés",
      Forex: "Forex",
      Stocks: "Actions",
      Commodities: "Matières premières",
      Futures: "Futures",
      Crypto: "Crypto",
      CFDs: "CFDs",
      PricingPlans: "Nos plans tarifaires",
      StarterPlan: "Plan de démarrage – 250 EUR",
      TraderPlan: "Plan Trader – 500 EUR à 1000 EUR",
      AdvancedPlan: "Forfait avancé - 500 EUR",
      SignIn: "Connexion",
      Email: "E-mail",
      Password: "Mot de passe",
      DontHaveAccount: "Vous n’avez pas de compte?",
      AccountTypesTitle: "Types de compte",
      Standard: "Standard",
      Advanced: "Avancé",

      TradeWith: "Échangez avec",
      TrustYearsTitle:
        "Plus de 20 ans d'expertise : Faites confiance aux experts du marché",
      FastAndSecure:
        "Une manière rapide et sécurisée d'acheter ou d'échanger plus de 150 symboles",
    },
    markets: {
      forex: {
        name: "Forex",
        heading: "Le Marché des Devises",
        navigate: "/markets/forex",
        mainInfo:
          "Le trading de devises se déroule sur le marché des changes. La monnaie est essentielle car elle permet d'acheter des biens et services à la fois localement et à travers les frontières.",
        description: [
          "Le marché des changes, également connu sous le nom de Forex ou FX, est un marché mondial décentralisé ou de gré à gré (OTC) pour le trading de devises.",
          "Ce marché détermine les taux de change pour chaque devise. Il englobe tous les aspects de l'achat, de la vente et de l'échange de devises à des prix actuels ou déterminés.",
          "Le trading sur le Forex est effectué électroniquement de gré à gré (OTC), ce qui signifie que les transactions ont lieu via des réseaux informatiques entre les traders du monde entier, plutôt que sur une bourse centralisée.",
        ],
      },
      crypto: {
        name: "Crypto",
        navigate: "/markets/crypto",
        heading: "Révolution de la Cryptomonnaie",
        mainInfo:
          "La cryptomonnaie est une monnaie numérique ou virtuelle qui utilise la cryptographie pour la sécurité et fonctionne de manière indépendante d'une banque centrale.",
        description: [
          "Les marchés de la cryptomonnaie sont décentralisés et basés sur la technologie de la blockchain, permettant des transactions sécurisées et transparentes.",
          "Bitcoin, créé par une personne ou un groupe de personnes inconnu(e) utilisant le pseudonyme Satoshi Nakamoto, a été la première cryptomonnaie et reste la plus connue et la plus largement utilisée.",
          "La blockchain, la technologie sous-jacente des cryptomonnaies, est un registre distribué qui enregistre toutes les transactions sur un réseau d'ordinateurs, offrant sécurité et transparence.",
        ],
      },
      stocks: {
        name: "Actions",
        navigate: "/markets/stocks",
        heading: "Investir dans les Entreprises",
        mainInfo:
          "Les marchés boursiers représentent des places où les acheteurs et les vendeurs d'actions, qui sont des parts de propriété dans des entreprises, se réunissent pour échanger.",
        description: [
          "Investir dans des actions offre un potentiel d'appréciation du capital et de dividendes, mais comporte également des risques tels que la volatilité du marché.",
          "Les actions sont classées dans différents secteurs, tels que la technologie, les soins de santé et les biens de consommation, permettant aux investisseurs de diversifier leurs portefeuilles.",
          "Les indices boursiers, tels que le S&P 500 et le Dow Jones Industrial Average, suivent la performance d'un groupe d'actions et servent de référence pour le marché plus large.",
        ],
      },
      cfds: {
        name: "CFDs",
        navigate: "/markets/cfds",
        heading: "Trading Sans Possession",
        mainInfo:
          "Les contrats pour la différence (CFD) sont des instruments financiers dérivés qui permettent aux traders de spéculer sur les fluctuations de prix de divers actifs sans posséder l'actif sous-jacent.",
        description: [
          "Le trading de CFD offre des opportunités de levier et permet aux traders d'acheter (long) ou de vendre (court) des prix d'actifs.",
          "Les CFD permettent aux traders d'accéder à un large éventail de marchés, y compris les actions, les matières premières, les devises et les indices, sans avoir besoin de posséder physiquement l'actif sous-jacent.",
          "Le trading de CFD est populaire parmi les traders à la recherche de flexibilité et de la possibilité de profiter à la fois des marchés haussiers et baissiers.",
        ],
      },
      futures: {
        name: "Futures",
        navigate: "/markets/futures",
        heading: "Prédire les Prix Futurs",
        mainInfo:
          "Les contrats à terme sont des accords standardisés pour acheter ou vendre des actifs à un prix et à une date prédéterminés à l'avenir.",
        description: [
          "Ils sont négociés sur des bourses organisées et sont utilisés par les investisseurs pour gérer le risque et spéculer sur les mouvements de prix.",
          "Les contrats à terme existent pour divers actifs, y compris les matières premières (telles que l'or, le pétrole et le blé), les instruments financiers (tels que les indices boursiers et les taux d'intérêt) et les devises.",
          "Le trading de contrats à terme implique de prédire les mouvements de prix futurs et peut être hautement spéculatif et volatile.",
        ],
      },
      commodities: {
        name: "Matières premières",
        navigate: "/markets/commodities",
        heading: "Trading de Biens de Base",
        mainInfo:
          "Les marchés de matières premières impliquent le trading de biens primaires tels que l'énergie, les métaux et les produits agricoles.",
        description: [
          "Investir dans les matières premières permet de diversifier les portefeuilles d'investissement et de se protéger contre l'inflation.",
          "Les marchés de matières premières sont influencés par divers facteurs, notamment la dynamique de l'offre et de la demande, les événements géopolitiques et les indicateurs économiques.",
          "Le trading de matières premières implique l'achat et la vente de contrats à terme ou de contrats au comptant de matières premières, offrant des opportunités de couverture et de spéculation.",
        ],
      },
    },
    aboutUs: {
      header: "À propos de nous",
      content:
        "MS Limited est un important fournisseur mondial de liquidités dans les produits financiers, activé par la technologie. Fondée en 1997, nous développons continuellement notre équipe et notre organisation, élargissant notre offre de produits, notre vaste réseau de contreparties et nos capacités de trading pour garantir que nos hubs de trading en Europe et en APAC puissent fournir de la liquidité sur toutes les principales bourses et plates-formes de trading, 24 heures sur 24, dans le monde entier. Notre culture est unique. Nous sommes des entrepreneurs dans l'âme et nous stimulons l'innovation dans les marchés financiers. Nous sommes une équipe de passionnés, d'esprits créatifs travaillant ensemble dans diverses fonctions et pays.",
      button: "Commencer",
      securityTitle: "Sécurité des fonds",
      securityContent:
        "Nous proposons à nos clients des moyens sûrs et sécurisés de gérer les paiements mondiaux. Soyez assuré que nous utilisons des techniques de cryptage et de sécurité de pointe pour garantir que tous nos systèmes internes et en ligne sont impénétrables.",
      regulationTitle: "MS Limited est réglementé par la loi",
      regulationContent:
        "En tant qu'institution financière, MS Limited est tenue par la loi de mener ses activités de manière conforme aux intérêts de ses clients et conformément à toutes les réglementations nationales régissant les sociétés de change et les transferts de fonds. Nous nous engageons à fonctionner de manière sûre et saine, avec les normes légales et éthiques les plus élevées. Pour garantir cette sécurité, nous avons établi des normes strictes de conformité aux réglementations dans les juridictions où nous opérons. Nous travaillons avec les banques institutionnelles les plus respectées au monde. Nous détenons des comptes bancaires auprès de certaines des banques institutionnelles les plus respectées au monde, où les clients déposent et règlent les paiements. Nous prenons la sécurité très au sérieux et menons nos activités de manière conforme aux meilleurs intérêts de nos clients et de nos partenaires.",

      backgroundTitle: "Contexte",
      backgroundContent: [
        "Nous collectons et traitons certaines de vos informations personnelles afin de nous permettre de fonctionner en tant qu'entreprise, de vous fournir nos services et de respecter les obligations légales que nous avons en tant qu'entreprise de services financiers réglementée.",
        "En tant qu'entreprise technologique, nous faisons également appel à un certain nombre de tiers pour effectuer certaines fonctions commerciales, ce qui peut nécessiter le partage de vos informations avec eux.",
        "Cet avis de confidentialité détaille les informations personnelles que nous collectons et utilisons, la façon dont nous les protégeons, et les circonstances dans lesquelles nous pourrions les partager avec une autre personne. Il précise également vos droits concernant la gestion de vos informations et vous informe de la manière de nous contacter si vous avez des préoccupations.",
      ],
      whenWeuseTitle: "Lorsque vous utilisez notre produit",
      whenWeuseContent: [
        "Les informations personnelles que vous nous fournissez lors de votre inscription en tant que client. Cela inclut le nom complet, la date de naissance, l'adresse postale, l'adresse e-mail, la nationalité et le numéro d'identification nationale.",
        "Documents que nous demandons afin de vérifier votre identité, d'effectuer des contrôles contre le blanchiment d'argent et des vérifications de sécurité.",
        "Détails du compte bancaire que vous utilisez pour financer votre compte chez nous.",
        "Informations transactionnelles et agrégées relatives aux investissements que vous effectuez via notre produit.",
        "Informations techniques sur votre utilisation de notre application, y compris votre adresse IP, le système d'exploitation et l'identifiant de l'appareil.",
        "Analyses de la façon dont vous utilisez notre produit.",
        "Déclarations fiscales que vous nous soumettez.",
      ],
      yourRightsTitle: "Vos droits",
      yourRightsContent: [
        "Vous avez des droits en vertu de la loi et de cet avis de confidentialité concernant vos données personnelles. Si vous souhaitez faire une demande concernant ces droits, veuillez nous contacter par e-mail. Vous avez le droit de :",
        "demander l'accès aux informations que nous détenons à votre sujet.",
        "nous demander de mettre à jour toute information vous concernant qui est inexacte ou incorrecte.",
        "nous demander de supprimer, d'arrêter de traiter ou de limiter notre utilisation de vos informations (bien que si nous avons une obligation légale de conserver ces informations, nous ne pourrons pas les supprimer avant la fin de la période de rétention requise).",
        "nous demander de vous envoyer ou d'envoyer à une autre organisation une copie électronique de vos données personnelles (portabilité).",
      ],
      howToContactTitle: "Comment nous contacter",
      howToContactContent: [
        "Si vous avez des questions concernant cet avis, la manière dont nous gérons vos données personnelles ou si vous souhaitez exercer l'un de vos droits, veuillez nous contacter par e-mail à l'adresse suivante : support@MS-limited.co et nous ferons de notre mieux pour résoudre le problème.",
      ],
      howToContact: "support@MS-limited.co",
      whatWeCollectTitle: "Quelles informations nous collectons",
      whatWeCollectContent:
        "Pour vous fournir nos services, nous collectons et utilisons les données personnelles suivantes vous concernant à différents moments de vos interactions avec nous. Les données personnelles sont des informations qui se rapportent à une personne vivante identifiée ou identifiable, soit seules, soit combinées avec d'autres informations disponibles.",

      leverageTitle: "Effet de levier",
      leverageContent: [
        "Lorsqu'une position est ouverte sur le marché Forex, le marché se déplace soit en faveur soit contre la position du trader.",
        "Chaque élément dans le mouvement des prix correspond à un montant fixe de capital qui est ajouté ou soustrait du solde du compte de trading.",
        "Si le marché évolue dans le sens de la position du trader, il gagne de l'argent ; sinon, le trader perd de l'argent.",
        "Le trading de devises se fait sous forme de « contrats » pour un certain nombre de lots standard.",
        "Chaque lot équivaut à 100 000 unités de devise.",
        "Si le dollar est utilisé comme devise de cotation et que le trader ouvre une position pour un lot standard, il achète ou vend 100 000 unités de cette devise.",
        "Étant donné que le mouvement des prix d'une devise est mesuré en points - c'est-à-dire que chaque élément a une part de 0,0001 - lors du trading avec un lot standard, chaque élément coûte 10 $ (0,0001 x 100 000 $ = 10 $).",
        "Si la transaction rapporte 10 points de profit, le trader gagne 100 $.",
        "Si le prix va 10 points dans le sens opposé à la position, alors le trader perd 100 $.",
        "Tout le monde n'a pas un tel capital qui vous permet de trader des devises pour un montant de 100 000 $, vous pouvez donc utiliser un effet de levier, c'est-à-dire emprunter de l'argent à un courtier pour effectuer une transaction de 100 000 $ en l'absence de 100 000 $ sur votre compte de trading.",
        "Lorsque vous utilisez un effet de levier lors de l'ouverture d'une position, vous recevez un prêt en capital, mais cet argent ne vous parvient pas réellement sur le compte.",
        "Cependant, vous voyez comment le résultat actuel d'une position ouverte change, car maintenant chaque élément est plus cher, et le mouvement des prix dans un sens ou dans l'autre peut potentiellement apporter un profit ou une perte plus élevés.",
        "Lors de l'exécution d'opérations de trading selon les conditions de « Margin Trading », un changement relativement faible du taux de l'instrument peut avoir un impact significatif sur l'état du compte de trading du client en raison de l'effet de levier.",
        "Lorsque le marché évolue contre la position du client, il peut subir une perte correspondant au dépôt initial et à tout fonds supplémentaire déposé par lui pour maintenir des positions ouvertes.",
        "Le client est entièrement responsable de prendre en compte tous les risques, d'utiliser les ressources financières et de choisir une stratégie de trading appropriée.",
        "Lors de l'exécution d'opérations de trading dans des conditions de trading sur marge, même de petits mouvements de marché peuvent avoir un impact considérable sur le compte de trading d'un client en raison de l'effet de levier.",
        "Le client doit considérer que si la tendance sur le marché est contre lui, le client peut subir une perte totale de sa marge initiale et de tout fonds supplémentaire déposé pour maintenir des positions ouvertes.",
        "Le client assume la pleine responsabilité de tous les risques, des ressources financières utilisées et de la stratégie de trading choisie.",
      ],
      refundTitle: "Politique de remboursement",
      refundContent: [
        "La Politique de remboursement a été développée dans le but de réduire les risques financiers et juridiques de l'entreprise, d'observer les principes de la Politique de lutte contre le blanchiment d'argent et le financement du terrorisme.",
        "L'entreprise se réserve le droit de bloquer unilatéralement l'accès au profil privé, de suspendre l'activité de trading sur les comptes, d'annuler la demande d'entrée-sortie, ou de retourner de l'argent si la source d'argent ou l'activité du client contredit la Politique de lutte contre le blanchiment d'argent et le financement du terrorisme.",
        "L'entreprise n'annule pas les transactions commerciales réalisées, et se réserve donc le droit de retourner de l'argent à l'expéditeur, si dans un délai d'un mois à compter du moment du réapprovisionnement, aucune activité n'a été enregistrée sur les comptes de trading.",
        "L'entreprise se réserve le droit, pour certaines raisons objectives, et en cas de besoin de retourner de l'argent, les fonds seront reçus via tous les systèmes de paiement, y compris les cartes de crédit/débit.",
        "Ainsi, le remboursement de l'argent sera exécuté sur des plates-formes électroniques, et les coordonnées bancaires que le client a utilisées lors du dépôt de l'argent est l'endroit où la transaction aura lieu.",
        "Dans le cas où l'entreprise classe l'activité du client comme inappropriée, ou contredisant l'objectif habituel d'utilisation des services de l'entreprise où il y a une intention directe ou indirecte, illégale ou déloyale, l'entreprise se réserve le droit de travailler dans ce document, sans informer préalablement le client.",
        "Lors du réapprovisionnement du compte de trading par carte bancaire, le client est tenu de ne pas soumettre la demande de confirmation du paiement qui est déjà inscrit sur le compte de trading à la banque, ou au fournisseur de la carte bancaire de crédit/débit, tant en temps voulu qu'après la fin de l'utilisation des services de l'entreprise.",
        "Néanmoins, si l'entreprise reçoit un commentaire de paiement selon la transaction, nous nous réservons le droit de bloquer l'accès au profil privé, et de geler le solde actuel du client, ainsi que de renvoyer l'argent sur le compte personnel du client, suivant le paiement de tous les services et commissions.",
        "L'entreprise prendra toutes les mesures nécessaires pour empêcher et bloquer à la fois l'entrée et le retrait par des tiers d'argent du compte du client.",
        "L'entrée et la sortie d'argent du compte ne peuvent être effectuées que par le propriétaire de ce compte particulier.",
        "Une fois qu'une demande de retrait est soumise, il peut falloir jusqu'à trois jours ouvrables à MS Limited pour traiter la demande.",
        "Une fois la demande approuvée, veuillez prévoir un délai supplémentaire de 5 à 7 jours ouvrables pour que les fonds apparaissent sur votre compte.",
        "Annulation de votre compte - Il n'y a pas de coût supplémentaire si vous souhaitez annuler votre compte, il suffit de placer une demande de retrait, et de nous informer de votre désir de fermer votre compte.",
        "Les retraits seront traités selon la procédure de retrait décrite ci-dessus.",
      ],
    },
    rest: {
      SignUp: "S'inscrire",
      Email: "Email",
      Password: "Mot de passe",
      LastName: "Nom de famille",
      FirstName: "Prénom",
      Address: "Adresse",
      PostalCode: "Code Postal",
      Phone: "Numéro de téléphone",
      PromoCode: "Code promo",
      Country: "Pays",
      Currency: "Devise",
      Register: "S'inscrire",
      Navigate: "Naviguer",
      AboutUs: "À propos de nous",
      Markets: "Marchés",
      Pricing: "Tarifs",
      Account: "Compte",
      SignIn: "Se connecter",
      GetInto: "Se mettre dans",
      Trading: "Trading",
      AllreadyHave: "Vous avez déjà un compte ?",
      Loading: "Chargement",
    },
  },
};
